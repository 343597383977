<template>
  <div class="wrap-content">
    <div class="header margin-20">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录</span>
        <span>/ 详情</span>
      </div>
      <!-- <ul>
          <li
            :class="navName == item.value ? 'active' : ''"
            v-for="(item, i) in nav"
            :key="i"
            :id="'info' + i"
          >
            <a href="javascript:void(0)" @click="goAnchor(item.value)">{{
              item.title
            }}</a>
          </li>
        </ul> -->
      <div class="button">
        <!-- <Button class="btn-simple" @click="handleExport">导出</Button> -->
        <CommonHeader />
      </div>
    </div>
    <div class="banner">
      <img
        src="../../assets/img/重要签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '重要数据'"
      />
      <img
        src="../../assets/img/一般签.png"
        alt=""
        class="important-icon"
        v-if="cache.level == '一般数据'"
      />
      <div class="banner-content">
        <h2>{{ formBaisc.dataName }}</h2>
        <ul>
          <li>
            <span>所属机构：</span><span>{{ cache.submitInst }}</span>
          </li>
          <li>|</li>
          <li>
            <span>复核机构：</span><span>{{ cache.auditAgency }}</span>
          </li>
          <li>|</li>
          <li>
            <span>填报人：</span><span>{{ cache.submitter }}</span>
          </li>
          <li>|</li>
          <li>
            <span>复核人：</span><span>{{ cache.reviewer }}</span>
          </li>
          <li>|</li>
          <li>
            <span>复核通过时间：</span><span>{{ auditThroughTime }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="info" id="basic">
          <div class="title margin-20">
            <img src="../../assets/img/已通过-基本信息.png" alt="" />
            <span>基本信息</span>
          </div>
          <div class="form">
            <Form
              ref="formBaiscValidate"
              :model="formBaisc"
              :label-width="250"
              label-position="left"
            >
              <FormItem label="数据名称" prop="dataName">
                <div>{{ formBaisc.dataName }}</div>
              </FormItem>
              <FormItem label="数据类别" prop="standard">
                <div v-for="(item, i) in formBaisc.dataType" :key="i">
                  {{ item }}
                </div>
              </FormItem>
              <FormItem label="数据描述" prop="first">
                <div>{{ formBaisc.desc }}</div>
              </FormItem>
              <FormItem label="拟定等级" prop="second">
                <div>{{ formBaisc.level }}</div>
              </FormItem>
              <FormItem label="数据量（单位：GB）">
                <div>{{ formBaisc.dataSize }}</div>
              </FormItem>
              <FormItem label="数据记录数">
                <div>{{ formBaisc.dataCount }}</div>
              </FormItem>
              <FormItem label="数据载体类型" prop="level">
                <div>{{ formBaisc.cover }}</div>
              </FormItem>
              <FormItem label="信息系统名称" prop="payload">
                <div>{{ formBaisc.ratio }}</div>
              </FormItem>
              <FormItem label="所在数据中心" prop="source">
                <div>{{ formBaisc.precision }}</div>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <BackTop :bottom="100"></BackTop>
  </div>
</template>
    <script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
  inject,
} from "vue";
import province from "@/utils/province";
import { Message, Modal } from "view-ui-plus";
import Footer from "@/components/footer.vue";
import reject from "./components/reject.vue";
import record from "./components/record.vue";
import { useRouter, useRoute } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue";
import DownFiles from "@/utils/dowFile";
let id = ref("");
let level = ref(0);
let cache = ref({}); //当前数据
let router = useRouter();
let route = useRoute();
const handleBack = () => {
  router.back();
};

//验证数据是否填写完整
let formBaisc = ref({
  dataName: "",
  dataType: [],
  desc: "",
  level: "一般数据",
  source: "",
  dataSize: "",
  dataCount: "",
  cover: "",
  ratio: "",
  precision: "",
});
let axios = inject("axios");

//导出
const handleExport = () => {
  let url = `/content/infoExport?dataId=${id.value}`;
  axios.get(url, { responseType: "blob" }).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      const blob = res.data;
      // 获取文件名
      const content = res.headers["content-disposition"];
      const fileName = content && content.split(";")[1].split("filename=")[1];
      DownFiles(blob, fileName);
    }
  });
};

onMounted(() => {
  let query = route.query;
  if (query.id) {
    id.value = query.id;
    level.value = query.level;
    getDataDetails();
    getAuditDate();
  }
});
//查询暂存详情
const getDataDetails = () => {
  let url = `/content/generalInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      for (let key in data) {
        if (data[key] == "" || data[key] == null) {
          data[key] = "无";
        }
      }
      cache.value = data;

      id.value = data.id;
      formBaisc.value = {
        dataName: data.dataName,
        dataType: [],
        description: data.description,
        level: "一般数据",
        dataSize: data.dataSize,
        dataCount: data.dataCount,
        payload: data.payload,
        appName: data.appName,
        dataCenter: data.dataCenter,
        first: data.first,
      };
    }
  });
};
let auditThroughTime = ref("");
//查询复核通过时间
const getAuditDate = () => {
  let url = `/content/auditInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      auditThroughTime.value = res.data.data;
    }
  });
};
onBeforeUnmount(() => {
  document.getElementById("wrap").removeEventListener("scroll", listenScroll);
});
</script>
    
    <style lang="scss" scoped>
.wrap-content {
  padding: 0 50px;
  background: #fff;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // line-height: 70px;
  .return {
    display: flex;
    align-items: center;
    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      i {
        font-size: 18px;
        color: #000;
      }
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }
    span:last-child {
      color: #cccccc;
    }
  }
  > ul {
    display: flex;
    li {
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
      line-height: 70px;
      position: relative;
      a {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 46px;
        letter-spacing: 0px;
        color: #555555;
      }
    }
    li.active {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        height: 3px;
        background-color: #c22b22;
        transform: translate(-50%, -50%);
      }
      a {
        color: #c22b22;
        font-weight: bold;
      }
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  //  height: ;
  width: calc(100% - 200px);
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  background: #fff;
  padding: 0 30px;
  > ul {
    li.active {
      &::before {
        bottom: -2px;
      }
    }
  }
}
.main {
  .title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #111;
    img {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
.remark {
  display: flex;
  label {
    font-size: 14px;
    display: block;
    min-width: 80px;
    margin-bottom: 10px;
    width: 250px;
  }
  > div {
    width: calc(100% - 250px);
    font-family: PingFangSC-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #111111;
  }
}
.footer {
  padding-top: 50px;
  border-top: 1px solid #ddd;
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

//标题logo
.title-logo {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.main {
  display: flex;
  padding-bottom: 50px;
  .main-content {
    width: 100%;
  }
}
.btn {
  padding: 0 25px;
}
.form {
  padding-left: 21px;
  ::v-deep .ivu-form {
    .ivu-form-item {
      margin-bottom: 0;
    }
    .ivu-form-item-content {
      div {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: 0px;
        color: #111111;
      }
    }
  }
}
.banner {
  background: url(../../assets/img/目录详情顶部banner.png) no-repeat;
  height: 200px;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -30px;
  .important-icon {
    display: block;
    width: 50px;
    height: 48px;
    position: absolute;
    top: 15px;
    right: 30px;
  }
  .banner-content {
    text-align: center;
    h2 {
      color: #111111;
      font-family: PingFangSC-Regular;
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      margin-bottom: 30px;
    }
    ul {
      display: flex;
      align-items: center;

      li {
        color: #555555;
        margin: 0 10px;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}
.margin-20 {
  margin-bottom: 10px;
}
</style>